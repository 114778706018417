/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { startsWith } from 'lodash'
import { validatePhoneNumber } from '../../../../../utils/phoneNumber.util'

const PhoneNumberWithCountryCode = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	onError,
	scrollToBottom = () => {},
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const validateNumber = (phoneNumber, countryCode) => {
		if (validatePhoneNumber(phoneNumber, countryCode)) {
			onError(null)
			onValidate(true)
		} else {
			onValidate(false)
			onError('Invalid phone number')
		}
	}

	const onChange = (phoneNumber, countryCode, insertValue) => {
		if (!!phoneNumber) {
			validateNumber(phoneNumber, countryCode)
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(field, insertValue),
			})
		}
	}

	let value1 = fields[field.field_id] ? fields[field.field_id].field_value : ''

	let value = value1.includes('|')
		? value1.includes('+')
			? value1
			: '+' + value1
		: value1.length > 4
		? '+91|' + value1
		: value1

	useEffect(() => {
		let countryCode = value.split('|')[0]
		let phoneNumber = value.replace('|', '')
		if (isRequired) {
			if (value.length === 0 || !value.includes('|')) {
				onValidate(false)
			} else {
				countryCode = value.split('|')[0]
				phoneNumber = value.replace('|', '')
				validateNumber(phoneNumber, countryCode)
			}
		} else {
			onValidate(true)
			countryCode = value.split('|')[0]
			phoneNumber = value.replace('|', '')
			validateNumber(phoneNumber, countryCode)
		}
	}, [value, isRequired])

	return (
		<PhoneInput
			onChange={(v, c, e, fv) => {
				let insertValue = fv.replace(/ /g, '|').replace(/-/g, '')
				let countryCode = '+' + c.dialCode
				let formattedValue = '+' + v
				onChange(formattedValue, countryCode, insertValue)
			}}
			value={value}
			disabled={isDisabled}
			country={'in'}
			onlyCountries={[
				'au',
				'in',
				'us',
				'bd',
				'gb',
				'de',
				'fr',
				'lb',
				'hk',
				'sk',
			]}
			countryCodeEditable={false}
			name='phone_number'
			enableSearch
			buttonStyle={{ border: 'none' }}
			buttonClass='rounded '
			inputStyle={{
				maxWidth: '260px',
				margin: 'auto',
				border: 'none',
				boxShadow: '0 2px 6px rgb(0,0,0,0.05)',
			}}
			isValid={(inputNumber, country, countries) => {
				scrollToBottom('select-scroll')
				return countries.some(country => {
					return (
						startsWith(inputNumber, country.dialCode) ||
						startsWith(country.dialCode, inputNumber)
					)
				})
			}}
		/>
	)
}

export default PhoneNumberWithCountryCode
