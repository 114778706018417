import {
	Box,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	ModalContent,
	Button,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
} from '@chakra-ui/react'
import React from 'react'

const OrderInfo = ({ onClose, isOpen, orderInfo, onPayNow, onPay }) => {
	return (
		<Box>
			<Modal onClose={onClose} size='md' isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Order Info</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<TableContainer>
							<Table size='sm'>
								<Thead>
									<Tr>
										<Th>Sl.N</Th>
										<Th>Field Name</Th>
										<Th>Field Value</Th>
									</Tr>
								</Thead>
								<Tbody>
									{orderInfo &&
										orderInfo.map((val, i) => (
											<Tr key={i}>
												<Td>{i + 1}</Td>
												<Td>{val.field_name}</Td>
												<Td>{val.field_value}</Td>
											</Tr>
										))}
								</Tbody>
							</Table>
						</TableContainer>
					</ModalBody>
					<ModalFooter>
						<Button
							bg={localStorage.getItem('color')}
							variant='filled'
							color='white'
							borderRadius={6}
							boxShadow='md'
							marginRight='7px'
							onClick={onPayNow}
							isDisabled={onPay}
						>
							Pay Now
						</Button>
						<Button onClick={onClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	)
}

export default OrderInfo
