/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import FileInput from '../../../../fileInput';
import { useSmartFormContext } from '../../../../../context/smart-form.context';
import { smartFormConst } from '../../../../../utils/action.constant';
import { loadFinalValue } from '../../../../../utils/form.util';
/**
 *  dataTypeId 35
 *  dataTypeName QRCode
 *
 * */
const supportedFiles = ['image/jpeg', 'image/png', 'application/pdf'];
const QRCode = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  isFileUpload,
  onDelete,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext();
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {};

  const onChange = file => {
    const { url } = file;

    if (!!isRequired) {
      if (!!url) {
        onValidate(true);
      } else {
        onValidate(false);
      }
    } else {
      onValidate(true);
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, url),
    });
  };

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange(fields[field.field_id].field_value);
    }
  }, []);

  return (
    <FileInput
      field={field}
      onChange={onChange}
      isDisabled={isDisabled}
      placeholder=".pdf"
      value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
      isFileUpload={isFileUpload}
      supportedFiles={supportedFiles}
      onDelete={onDelete}
    />
  );
};

export default QRCode;
