/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
	loadFinalValue,
	vilFieldConfigData,
	arithmaticCalcu,
} from '../../../../../utils/form.util'
import calcStub from '../../../../../utils/vil_form_config.util'
import { toSnakeCase } from '../../../../../utils/common.util'
const VALIDATION_SCHEMA = {
	12: /^[0-9.]+$/, ///Only  Numeric Chars
} // 12- only psitive numbers:, 11:- all numbers

const ERROR_MESSAGE = {
	12: 'Invalid input only positive numeric characters allowed',
}
const DecimalField = ({
	field,
	isRequired,
	isDisabled = true,
	onValidate,
	isFromEdit = false,
	onError,
	fieldList,
}) => {
	const {
		state: { fields: formFields, toReFreshNumVal },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	if (
		Object.keys(calcStub).includes(`${field.form_workflow_activity_type_id}`) &&
		!isFromEdit
	) {
		const processId = field.form_workflow_activity_type_id
		if (
			!!calcStub[processId] &&
			!!calcStub[processId][field.field_id] &&
			!!calcStub[processId][field.field_id].calculateValue
		) {
			const dataValue = vilFieldConfigData(field, fields)
			onValidate(true)
			if (!fields[field.field_id] && !isNaN(dataValue)) {
				dispatch({
					type: smartFormConst.SET_FIELD_VALUE,
					fieldId: field.field_id,
					formId: field.form_id,
					value: loadFinalValue(field, dataValue),
				})
			} else if (!isNaN(dataValue)) {
				if (Number(fields[field.field_id].field_value) !== Number(dataValue)) {
					dispatch({
						type: smartFormConst.SET_FIELD_VALUE,
						fieldId: field.field_id,
						formId: field.form_id,
						value: loadFinalValue(field, dataValue),
					})
				}
			}
		}
	}

	const onChange = value => {
		if (!!isRequired) {
			if (!!value) {
				if (
					!!field.field_inline_data &&
					field.field_inline_data.flag_input_setting >= 2
				) {
					if (
						!!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting] &&
						!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting].test(
							value
						)
					) {
						onValidate(false)
						onError(ERROR_MESSAGE[field.field_inline_data.flag_input_setting])
					} else {
						onValidate(true)
					}
				} else {
					onValidate(true)
				}
				let inlineData = field?.field_inline_data
				let validationSet = inlineData?.flag_validation_setting
				let validationCombID = fields[validationSet?.validation_field_id]
				validationCombID = validationCombID?.final?.data_type_combo_id
				let fieldValidations = (validationSet?.validation_options || []).find(
					val => val?.combo_id === validationCombID
				)

				// console.log({ fieldValidations, validationCombID }, 'validations ---->');
				let minLength = Number(fieldValidations?.flag_validation_value_min)
				let maxLength = Number(fieldValidations?.flag_validation_value_max)
				if (inlineData && inlineData.flag_validation_enabled) {
					if (
						fieldValidations &&
						fieldValidations?.flag_validation_inclusion === 1 &&
						!(value >= minLength && value <= maxLength)
					) {
						onValidate(false)
						onError(
							`Your input must be less than or equal to ${maxLength} and greater than or equal to ${minLength}`
						)
					} else if (
						fieldValidations &&
						fieldValidations?.flag_validation_inclusion === 2 &&
						!(value >= minLength && value < maxLength)
					) {
						onValidate(false)
						onError(`Your input must be greater than or equal to ${minLength}`)
					} else if (
						fieldValidations &&
						fieldValidations?.flag_validation_inclusion === 3 &&
						!(value <= maxLength && value > minLength)
					) {
						onValidate(false)
						onError(`Your input must be less than or equal to ${maxLength}`)
					} else {
						onValidate(true)
					}
				}
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, value),
		})
		dispatch({
			type: smartFormConst.TO_REFRESH_NUM_VAL,
		})
	}

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange(fields[field.field_id].field_value.toString())
		}
	}, [fields[field.field_id]?.field_value])

	useEffect(() => {
		if (
			!!field?.field_inline_data?.formula?.length &&
			!!formFields[field.form_id]
		) {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(
					field,
					arithmaticCalcu(
						field,
						formFields[field.form_id],
						fieldList
					).toString()
				),
			})
			onValidate(true)
		}
	}, [
		toReFreshNumVal,
		formFields?.[field.form_id]?.[field.field_id]?.field_value,
	])

	return (
		<NumberInput
			className={`decimal-field-${toSnakeCase('' + field.field_id)} ${
				fields[field.field_id] ? fields[field.field_id].field_value : 0
			}`}
			precision={2}
			isDisabled={
				isDisabled ||
				(!!field?.field_inline_data?.formula?.length &&
					!!formFields[field.form_id])
			}
			onChange={onChange}
			value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
		>
			<NumberInputField
				placeholder={field.field_place_holder || `Enter ${field.field_name}`}
			/>
			<NumberInputStepper>
				<NumberIncrementStepper />
				<NumberDecrementStepper />
			</NumberInputStepper>
		</NumberInput>
	)
}

export default DecimalField
