/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLanguageContext } from '../../../../../context/locale.context'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useSharedContext } from '../../../../../context/shared.context'
import { uuid } from 'uuidv4'
import {
	usePaymentChanelData,
	useGetSignature,
	useCreateOrder,
	useGatewayrouting,
} from '../../../../../hooks/form.hooks'
import {
	Box,
	Button,
	Icon,
	useDisclosure,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	VStack,
} from '@chakra-ui/react'

import { MdPayment } from 'react-icons/md'
import OrderInfo from './OrderInfo'

let displayObj = {
	'Order No': 'merchant_txn_ref_no',
	Amount: 'amount',
	'GreneOS No': 'transaction_id',
	'PG Refrence No': 'pg_ref_no',
	'Payment DateTime': 'paymentDateTime',
	'Payment Mode': 'payment_inst_type',
	'Payment Status': 'payment_response_desc',
}

let statusCode = {
	'00': 'success',
	21: 'warning',
	'*': 'error',
}
const Payment = ({
	field,
	isRequired,
	isDisabled = true,
	onValidate,
	isBulkEdit,
	fieldIndex,
	activity,
	...rest
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const { field_id = '', form_id = '' } = field
	const { activity_type_id = '', activity_id = '' } = activity || {}
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [orderInfo, setorderInfo] = useState([])
	const [gateWayData, setGateWayData] = useState({})
	const [dummy, setdummy] = useState(true)
	const [onPay, setOnPay] = useState(false)
	const [paymentStatus, setPaymentStatus] = useState(true)
	const [paymentData, setPaymentData] = useState(null)

	const { mutate } = usePaymentChanelData()
	const { mutate: getSignature } = useGetSignature()
	const { mutate: createOrder } = useCreateOrder()
	const { mutate: geteWayrouting } = useGatewayrouting()

	useEffect(() => {
		mutate(
			{
				activity_type_id: activity_type_id,
				form_id: form_id,
				field_id: field_id,
				workflow_activity_id: Number(activity_id),
			},
			{
				onSuccess: async data => {
					setorderInfo(data)
					setdummy(!dummy)
					localStorage.setItem('orderInfo', JSON.stringify(data))
				},
			}
		)
	}, [])

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			try {
				let jsonData = JSON.parse(fields[field.field_id].field_value.toString())
				if (jsonData.payment_response_code === '00') {
					setPaymentStatus(false)
				} else {
					setPaymentStatus(true)
				}
				setPaymentData(jsonData)
				onValidate(true)
			} catch (err) {}
		}
	}, [fields[field.field_id]?.field_value])

	const buildSignatureParams = () => {
		return {
			merchant_id: 'M00001',
			currency: 'INR',
			is_parent: 0,
			activity_id: activity_id,
			activity_type_id: activity_type_id,
			merchant_txn_ref_no: uuid(),
			reservation_id: 0,
			amount: toGetFieldValue('Total'), //Total
			description: `paying for ${toGetFieldValue('Plan for')} plan`,
			sub_order_list: [],
			customer_mobile_no: toGetFieldValue('Contact Number'),
			customer_first_name: toGetFieldValue('First Name'),
			customer_last_name: toGetFieldValue('Last Name'),
			customer_emailid: toGetFieldValue('Email Id'),
		}
	}

	const toGetSignature = () => {
		let paramsData = buildSignatureParams()
		getSignature(
			{
				...paramsData,
			},
			{
				onSuccess: async data => {
					//signature
					if (data.hasOwnProperty('signature') && data.signature) {
						createOrder(
							{
								...paramsData,
								signature: data.signature,
							},
							{
								onSuccess: async res => {
									if (!res) return
									geteWayrouting(
										{
											...paramsData,
											signature: data.signature,
										},
										{
											onSuccess: async response => {
												setGateWayData(response)
												setOnPay(true)
												setdummy(!dummy)
												setTimeout(() => {
													let theForm = document.getElementById('payForm')
													theForm.submit()
												}, 1000)
											},
										}
									)
								},
							}
						)
					}
				},
			}
		)
	}

	const toGetFieldValue = field => {
		return orderInfo.find(el => el.field_name === field)?.field_value || ''
	}

	return (
		<Box textAlign='center'>
			<VStack>
				{paymentData ? (
					<TableContainer>
						<Table size='sm'>
							<Thead>
								<Tr>
									<Th>Sl.N</Th>
									<Th>Key</Th>
									<Th>Value</Th>
								</Tr>
							</Thead>
							<Tbody>
								{paymentData &&
									Object.keys(displayObj).map((val, i) => (
										<Tr key={i}>
											<Td>{i + 1}</Td>
											<Td>{val}</Td>
											<Td>{paymentData[displayObj[val]]}</Td>
										</Tr>
									))}
							</Tbody>
						</Table>
					</TableContainer>
				) : null}
				{paymentStatus ? (
					<Button
						// w="100%"
						leftIcon={<Icon as={MdPayment} w={8} h={8} />}
						variant='filled'
						bg={localStorage.getItem('color')}
						color='white'
						borderRadius={6}
						fontWeight='400'
						boxShadow='md'
						textTransform='capitalize'
						onClick={() => {
							onOpen()
						}}
					>
						{paymentData?.payment_response_code === '39' ? 'Pay Again' : 'Pay'}
					</Button>
				) : null}
			</VStack>

			<OrderInfo
				isOpen={isOpen}
				onClose={onClose}
				orderInfo={orderInfo}
				onPayNow={toGetSignature}
				onPay={onPay}
			/>

			<Box>
				<form
					id='payForm'
					style={{ visibility: 'hidden' }}
					name='payForm'
					action={gateWayData.actionUrl}
					method='post'
					enctype='application/x-www-form-urlencoded'
				>
					{Object.keys(gateWayData).length > 0
						? Object.keys(gateWayData).map((val, i) => (
								<input
									type='text'
									key={i}
									name={val}
									value={gateWayData[val]}
								/>
						  ))
						: ''}
				</form>
			</Box>
		</Box>
	)
}

export default Payment
