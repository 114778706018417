/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react'
import {
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue, arithmaticCalcu } from '../../../../../utils/form.util'
import { toSnakeCase } from '../../../../../utils/common.util'
import { useLoadFormFieldsList } from '../../../../../hooks/activity.hooks'

const VALIDATION_SCHEMA = {
	12: /^[0-9]+$/, ///Only  Numeric Chars
} // 12- only psitive numbers:, 11:- all numbers

const ERROR_MESSAGE = {
	12: 'Invalid input only positive numeric characters allowed',
}
/* allowMouseWheel to increament the value via mouse wheel */

const NumberField = ({
	field,
	isDisabled = true,
	isRequired,
	onValidate,
	onError,
	fieldList,
}) => {
	const {
		state: { fields: formFields, toReFreshNumVal },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const onChange = value => {
		if (!!isRequired) {
			if (!!value) {
				if (value.includes('.')) {
					onValidate(false)
				}
				if (
					!!field.field_inline_data &&
					field.field_inline_data.flag_input_setting >= 2
				) {
					if (
						!!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting] &&
						!VALIDATION_SCHEMA[field.field_inline_data.flag_input_setting].test(
							value
						)
					) {
						onValidate(false)
						onError(ERROR_MESSAGE[field.field_inline_data.flag_input_setting])
					} else {
						onValidate(true)
					}
				} else {
					onValidate(true)
				}

				let inlineData = field?.field_inline_data
				let validationSet = inlineData?.flag_validation_setting
				let validationCombID = fields[validationSet?.validation_field_id]
				validationCombID = validationCombID?.final?.data_type_combo_id
				let fieldValidations = (validationSet?.validation_options || []).find(
					val => val?.combo_id === validationCombID
				)

				// console.log({ fieldValidations, validationCombID }, 'validations ---->');
				let minLength = Number(fieldValidations?.flag_validation_value_min)
				let maxLength = Number(fieldValidations?.flag_validation_value_max)
				if (inlineData && inlineData.flag_validation_enabled) {
					if (
						fieldValidations &&
						fieldValidations?.flag_validation_inclusion === 1 &&
						!(value >= minLength && value <= maxLength)
					) {
						onValidate(false)
						onError(
							`Your input must be less than or equal to ${maxLength} and greater than or equal to ${minLength}`
						)
					} else if (
						fieldValidations &&
						fieldValidations?.flag_validation_inclusion === 2 &&
						!(value >= minLength && value < maxLength)
					) {
						onValidate(false)
						onError(
							`Your input must be greater than or equal to ${minLength} and less than ${maxLength}`
						)
					} else if (
						fieldValidations &&
						fieldValidations?.flag_validation_inclusion === 3 &&
						!(value <= maxLength && value > minLength)
					) {
						onValidate(false)
						onError(
							`Your input must be less than or equal to ${maxLength} and greater than ${minLength}`
						)
					} else {
						onValidate(true)
					}
				}
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
			if (!!value && value.includes('.')) {
				onValidate(false)
			}
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, value),
		})
		dispatch({
			type: smartFormConst.TO_REFRESH_NUM_VAL,
		})
	}

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange(fields[field.field_id]?.field_value?.toString())
		}
	}, [fields[field.field_id]?.field_value])

	useEffect(() => {
		if (
			!!field?.field_inline_data?.formula?.length &&
			!!formFields[field.form_id]
		) {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(
					field,
					arithmaticCalcu(
						field,
						formFields[field.form_id],
						fieldList
					).toString()
				),
			})
			onValidate(true)
		}
	}, [
		toReFreshNumVal,
		formFields?.[field.form_id]?.[field.field_id]?.field_value,
	])
	return (
		<NumberInput
			className={`number-field-${toSnakeCase(field.field_name)} ${
				fields[field.field_id] ? fields[field.field_id].field_value : 0
			}`}
			onChange={onChange}
			value={
				fields[field.field_id]
					? fields[field.field_id].field_value
						? fields[field.field_id].field_value
						: ''
					: ''
			}
			isDisabled={
				isDisabled ||
				(!!field?.field_inline_data?.formula?.length &&
					!!formFields[field.form_id])
			}
			onKeyDown={e => {
				if (e.key === '.') {
					e.preventDefault()
				}
			}}
		>
			<NumberInputField
				placeholder={field.field_place_holder || `Enter ${field.field_name}`}
			/>
			<NumberInputStepper className={`number-field-stepper`}>
				<NumberIncrementStepper className={`number-field-increment`} />
				<NumberDecrementStepper className={`number-field-decrement`} />
			</NumberInputStepper>
		</NumberInput>
	)
}

export default NumberField
